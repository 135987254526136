import React, { useState } from "react";
import Styled from "styled-components";
import logo from "./images/Frame.svg";
import phone from "./images/phone.svg";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const Header = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const [showDropDown, setShowDropDown] = useState(false);
  const [open, setOpen] = useState(false);
  return (
    <Nav
      onClick={() => {
        showDropDown && setShowDropDown(false);
      }}
    >
      <Logo data-aos="fade-right">
        <a href="/" >
          <img src={logo} alt="logo" />
        </a>
      </Logo>
      <Menu open={open}>
        <About data-aos="fade-up">
          <MenuLink href="#">About us</MenuLink>
        </About>
        <Services data-aos="fade-down">
          <MenuLink onClick={() => setShowDropDown(true)} href="#">
            Services
          </MenuLink>
          <Dropdown
            style={showDropDown ? { display: "block" } : { display: "none" }}
            onClick={() => setShowDropDown(false)}
          >
            <DropdownList href="https://pbgdigital.co.uk/services">
              Digital Solutions
            </DropdownList>
            <DropdownList href="https://pbgcare.co.uk/our-services/">
              Care Services
            </DropdownList>
            <DropdownList href="https://progress.terrelldavies.com/">
              Real Estate
            </DropdownList>
            <DropdownList href="#">Philanthropy</DropdownList>
          </Dropdown>
        </Services>
        <Contact data-aos="fade-up">
          <MenuLink href="/contact">Contact us</MenuLink>
        </Contact>
      </Menu>
      <Hamburger open={open} onClick={() => setOpen(!open)}>
        <span />
        <span />
        <span />
      </Hamburger>
      <Phone data-aos="fade-left">
        <img src={phone} alt="call" />
        <a href="tel:01322686765"> +44 132 2686 765</a>
      </Phone>
    </Nav>
  );
};

const Nav = Styled.div`
  display: flex;
  justify-content: space-between;  
  align-items: center; 
  flex-wrap: wrap;
  background: #FFFFFF; 
  height: 64px; 
  width: 100%;
  z-index: 18;

  @media screen and (min-height: 1050px) {
    height: 10vh;
  }

    
`;

const Logo = Styled.div`
  position: absolute; 
  margin-left: 35px;
  margin-top: 2px;
`;

const Hamburger = Styled.div`
  width: 1.5rem;
  height: 2rem;
  display: none;
  position: fixed;
  cursor: pointer;
  top: 15px;
  right: 20px;
  z-index: 20;

  @media (max-width: 768px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }

  span {
    height: 2px;
    width: 2.05rem;
    background: ${({ open }) => (open ? "#ccc" : "#606060")};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;

    &:nth-child(1) {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    &:nth-child(2) {
      transform: ${({ open }) => (open ? "translateX(100%)" : "translateX(0)")};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }

    &:nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

const Menu = Styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative; 
  margin-left: 450px;

  @media (max-width: 768px) {
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")}; 
    flex-flow: column nowrap;
    background-color: #606060;
    position: fixed;
    z-index: 20;
    top: 0;
    right: 0;
    height: 50vh;
    width: 100%;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
  }
`;

const MenuLink = Styled.a`
  padding: 1rem 4.5rem;
  cursor: pointer;
  text-align: center;
  font-size: 15px;
  color: #606060;
  transition: all 0.3s ease-in; 

  &:hover {
    color: #000000;
  }

  @media (max-width: 768px) {
    color: #fff;
  }
`;

const About = Styled.p`
  @media (max-width: 768px) {
  }
`;

const Services = Styled.p`
  @media (max-width: 768px) {
  }
`;

const Dropdown = Styled.div`
  background: white;
  position: absolute;
  left: 50%;
  transform: translatex(-50%);
  min-width: 140px;
  height: auto;
  font-size: 12px; 
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #DCDCDC;
  z-index: 20;
`;

const DropdownList = Styled.a`
  display: flex;
  text-align: center;  
  color: #606060;
  margin-top: 1px;
  padding: 1rem 1rem;
  border-radius: 5px;

  &:hover {
    color: #000000;
    background: #C0C0C0;
  }
`;

const Contact = Styled.p`
  @media (max-width: 768px) {
    padding-bottom: 4rem;
  }
`;

const Phone = Styled.p`
  margin-right: 3rem;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }
  a {
    color: #606060;
    margin-left: 0.2rem;
    &:hover {
      color: #000;
    }
  }
`;

export default Header;
