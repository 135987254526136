import React from "react";
import Styled from "styled-components";
import Card from "./Card/Card";
import bg2 from "./Images/background2.png";
import bg1 from "./Images/background.png";
import bg3 from "./Images/background3.png";
import logo1 from "./Images/Frame.svg";
import logo2 from "./Images/Frame2.svg";
import logo3 from "./Images/Frame3.svg";

const Body = () => {
  const cardData = [
    {
      key: "digital",
      background: bg1,
      logo: logo1,
      title: "Digital Solutions Guaranteed",
      paragraph:
        "We provide digital solutions ranging from web development, design & branding, mobile app development, and digital marketing.",
      buttonUrl: "https://pbgdigital.co.uk/",
      igUrl: "https://www.instagram.com/prudent_digital/",
    },
    {
      key: "care",
      background: bg2,
      logo: logo2,
      title: "Providing the home care you need",
      paragraph:
        "With coverage areas in Bexley and Royal Borough of Greenwich, we are more than equipped to meet your home care needs.",
      buttonUrl: "https://pbgcare.co.uk/",
      igUrl: "https://www.instagram.com/prudentdomiciliarycare/",
    },
    {
      key: "telles",
      background: bg3,
      logo: logo3,
      title: "Make informed real estate decisions",
      paragraph:
        "We provide housing solutions that suit your needs and assist you in making the best decisions regarding real estate.",
      buttonUrl: "https://progress.terrelldavies.com/",
      igUrl: "https://www.instagram.com/terrell_davies_/",
    },
  ];

  return (
    <BodyStyle>
      {cardData.map((card) => (
        <Card
          key={card.key}
          background={card.background}
          logo={card.logo}
          title={card.title}
          paragraph={card.paragraph}
          buttonUrl={card.buttonUrl}
          igUrl={card.igUrl}
        />
      ))}
    </BodyStyle>
  );
};

const BodyStyle = Styled.div`
  width: 100%;
  height: 600px;
  display: flex;

  @media screen and (max-width: 789px) {
    display: block;
    height: auto;
  }

  @media screen and (min-height: 1050px) {
    height: 60vh;
  }

`;

export default Body;
